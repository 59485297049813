<style lang="scss"></style>

<template>
  <svg
    width="6"
    height="8"
    viewBox="0 0 6 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 8V0L6 4L0 8Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'IconPlay',
  components: {},
  data() {
    return {};
  },
  props: {},
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
