<style lang="scss"></style>

<template>
  <img
    style="width: 124px"
    :src="logo"
  />
</template>

<script>import { CONF_SETTINGS } from "../../confSettings";

export default {
  name: 'LogoConf',
  components: {},
  data() {
    return {
      logo: CONF_SETTINGS.logo
    };
  },
};
</script>
