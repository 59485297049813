<template>
  <table class="top-table">
    <thead>
      <tr>
        <td>ПОЗИЦИЯ</td>
        <td>ПРОЕКТ/КОМАНДА</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr class="current" v-for="job in jobs" :key="job.id">
        <td>{{ job.pos }}</td>
        <td>{{ job.project }}</td>
        <td>
          <a :href="job.telegram" target="_blank">
            <div class="telegram" />
          </a>
          <a class="geecko-button--outline" :href="job.href" target="_blank">Смотреть</a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TopTable",
  components: {},
  props: {
    jobs: {
      type: Array
    }
  },
  data() {
    return {};
  },
  computed: {
  },
  watch: {},
  mounted() {
    console.log(this.jobs)
  },
  methods: {
    jobLink(link) {
      this.$router.push(link)
    }
  },
};
</script>
<style lang="scss" scoped>
.telegram {
  background: url("/images/telegramm.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-width: 40px !important;
  height: 40px;
  cursor: pointer;
  // border: 1px solid red;
}
.top-table {
  font-family: "BlenderPro";
  width: 100%;
  border-spacing: 0 3px;
  border-collapse: separate;
  & tr {
    background: rgba(39, 36, 56, 0.8);
  }
  & thead {
    font-size: 16px;
    line-height: 120%;
    color: #7f7e8a;
    & td {
      padding-top: 6px;
      padding-bottom: 11px;
      &:first-child {
        text-align: start;
        padding-left: 24px;
      }
      &:last-child {
        text-align: end;
        padding-right: 24px;
      }
    }
  }
  & tbody {
    font-size: 24px;
    line-height: 36px;
    color: rgba(255, 255, 255, 0.9);
    & td {
      padding-top: 18px;
      padding-bottom: 18px;
      &:first-child {
        text-align: start;
        // border: 1px solid red;
        font-family: "BlenderPro Bold";
        font-size: 20px;
        line-height: 100%;
        color: #fff;
        width: 230px;
        padding-left: 24px;
      }
      &:nth-child(2) {
        font-size: 15px;
        font-weight: 100 !important;
      }
      .geecko-button--outline {
        font-size: 14px;
      }
    }
    & tr {
      &.current {
        background: #1f1d2b;
        border: 2px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.table-time {
  width: 50px;
}

.table-score {
  width: 180px;
}

@media (max-width: 768px) {
  .top-table {
    & thead {
      font-size: 12px;
      & td {
        padding-top: 6px;
        padding-bottom: 6px;
        &:last-child {
          padding-right: 16px;
        }
      }
    }
    & tbody {
      font-size: 16px;
      line-height: 15px;
      font-weight: 300;
      & td {
        padding-top: 16px;
        padding-bottom: 16px;
        font-weight: 300;
        &:first-child {
          font-size: 12px;
          line-height: 100%;
          width: 130px;
        }
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 200 !important;
        }
        &:last-child {
          padding-right: 16px;
        }
        .geecko-button--outline {
          font-size: 14px;
          padding: 10px 10px 10px 10px;
          height: 60px;
        }
      }
    }
  }
}
</style>
